import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stylesOrig from './MobileMenuItem.scss';
import stylesClean from './MobileMenuItemClean.scss';
import formatTestId from '../../../utils/test-id/format-testid';

const BackButton = ({
  label,
  onClick,
  isCleanMobileNavActive,
  isMobileNavTestActive,
}) => {
  const styles = isCleanMobileNavActive ? stylesClean : stylesOrig;

  return (
    <div
      className={classNames(
        styles.mobileMenuItem,
        styles.mobileMenuItem__fullWidth,
        styles.mobileMenuItem__chevronLeft,
        {
          [styles.mobileMenuItem__static]: isCleanMobileNavActive,
          [styles.mobileMenuItem__chevronLeft__mobileNavTest]:
            isMobileNavTestActive?.b,
        }
      )}
    >
      {isMobileNavTestActive?.b ? (
        <div
          className={classNames(
            styles.mobileMenuItem__button,
            styles.mobileMenuItem__button__customBackButton
          )}
          data-testid="nav-back-button"
        >
          <button
            data-testid={formatTestId(label, 'nav-heading-')}
            className="small-back-button"
            onClick={onClick}
          />
          {label}
        </div>
      ) : (
        <button
          className={classNames(styles.mobileMenuItem__button)}
          data-testid="nav-back-button"
          onClick={onClick}
        >
          {label}
        </button>
      )}
    </div>
  );
};

BackButton.defaultProps = {
  isCleanMobileNavActive: false,
  isMobileNavTestActive: null,
};

const menuTestShape = {
  a: PropTypes.bool,
  b: PropTypes.bool,
};

BackButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isCleanMobileNavActive: PropTypes.bool,
  isMobileNavTestActive: PropTypes.oneOfType([
    PropTypes.shape(menuTestShape),
    PropTypes.oneOf([null]),
  ]),
};

export default BackButton;
