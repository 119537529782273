import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BackButton from '../mobile-menu-item/BackButton';
import MobileMenuItem from '../mobile-menu-item/MobileMenuItem';
import stylesOrig from './MobileMenu.scss';
import stylesClean from './MobileMenuClean.scss';
import notifyAnalytics from '../notify-analytics';
import formatTestId from '../../../utils/test-id/format-testid';
import offersCategory from '../offers-category';
import filterMenuData from '../../../utils/menu/filterMenuData';

const NestedMenuList = ({
  name,
  child,
  hierarchy,
  isCleanMobileNavActive,
  isMobileNavTestActive,
}) => {
  const nestedMenuListDataFiltered = filterMenuData(child, 'MOBILE_WEB');

  const styles = isCleanMobileNavActive ? stylesClean : stylesOrig;
  const isOffer = offersCategory(name, 1);

  return (
    <li className={styles.nestedMenuItem}>
      <h4
        className={classNames(styles.mobileMenu__nestedHeading, {
          [styles.mobileMenu__name__offer]: isOffer && isCleanMobileNavActive,
        })}
      >
        {name}
      </h4>
      <ul
        className={classNames(
          styles.mobileMenuList__nested,
          styles.mobileMenuList
        )}
      >
        {nestedMenuListDataFiltered.map(({ id, name: childName, url }) => (
          <MobileMenuItem
            key={id}
            label={childName}
            url={url}
            hierarchy={hierarchy}
            isCleanMobileNavActive={isCleanMobileNavActive}
            isMobileNavTestActive={isMobileNavTestActive}
          />
        ))}
      </ul>
    </li>
  );
};

const NestedMenu = ({
  data,
  hierarchy: parentHierarchy,
  onNavigateBack,
  isCleanMobileNavActive,
  isMobileNavTestActive,
}) => {
  const styles = isCleanMobileNavActive ? stylesClean : stylesOrig;
  const isOffer = offersCategory(data.name, 1);

  const nestedMenuDataChildFiltered = filterMenuData(data?.child, 'MOBILE_WEB');

  return (
    <div
      className={classNames(styles.subMenu, styles.categoryLevelMenu)}
      role="menu"
    >
      {data.backLabel && (
        <BackButton
          label={data.backLabel}
          onClick={onNavigateBack}
          isCleanMobileNavActive={isCleanMobileNavActive}
        />
      )}
      <h3
        className={classNames(styles.mobileMenu__name, {
          [styles.mobileMenu__name__offer]: isOffer && isCleanMobileNavActive,
        })}
      >
        {data.url ? (
          <a
            href={data.url}
            alt={data.name}
            onClick={() => notifyAnalytics(data.name)}
            data-testid={formatTestId(data.name, 'nav-heading-')}
          >
            {data.name}
          </a>
        ) : (
          data.name
        )}
      </h3>
      <ul className={styles.mobileMenuList}>
        {nestedMenuDataChildFiltered?.map(({ id, name, url, child }) =>
          child?.length > 0 ? (
            <NestedMenuList
              key={id}
              name={name}
              child={child}
              hierarchy={[...parentHierarchy, data.name, name]}
              isCleanMobileNavActive={isCleanMobileNavActive}
            />
          ) : (
            <MobileMenuItem
              key={id}
              label={name}
              url={url}
              hierarchy={[...parentHierarchy, data.name]}
              isCleanMobileNavActive={isCleanMobileNavActive}
              isMobileNavTestActive={isMobileNavTestActive}
            />
          )
        )}
      </ul>
    </div>
  );
};

NestedMenu.defaultProps = {
  onNavigateBack: undefined,
  hierarchy: [],
  data: {},
  isCleanMobileNavActive: false,
  isMobileNavTestActive: null,
};

const menuTestShape = {
  a: PropTypes.bool,
  b: PropTypes.bool,
};

NestedMenu.propTypes = {
  data: PropTypes.shape({
    backLabel: PropTypes.string,
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    child: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        url: PropTypes.string,
      })
    ),
  }),
  onNavigateBack: PropTypes.func,
  hierarchy: PropTypes.arrayOf(PropTypes.string),
  isCleanMobileNavActive: PropTypes.bool,
  isMobileNavTestActive: PropTypes.oneOfType([
    PropTypes.shape(menuTestShape),
    PropTypes.oneOf([null]),
  ]),
};

export default NestedMenu;
