import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BackButton from '../mobile-menu-item/BackButton';
import MobileMenuItem from '../mobile-menu-item/MobileMenuItem';
import stylesOrig from './MobileMenu.scss';
import stylesClean from './MobileMenuClean.scss';
import ContentBlock from '../content-block/ContentBlock';
import NestedMenu from './NestedMenu';
import postMonetateImpression from '../../../utils/monetate/postMonetateImpression';
import EnvVarsFeaturesContext from '../../../scaffold/EnvVarsFeaturesContext';
import notifyAnalytics from '../notify-analytics';
import formatTestId from '../../../utils/test-id/format-testid';
import offersCategory from '../offers-category';
import isMonetateGroup from '../../../utils/monetate/isMonetateGroup';
import filterMenuData from '../../../utils/menu/filterMenuData';
import StoreFInderButton from '../mobile-menu-item/StoreFinderButton';
import dispatchMonetateQEvent from '../../../utils/monetate/monetateEventDispatcher';

const SubMenu = ({
  setActiveLevel,
  subMenuData,
  onNavigateBack,
  onItemSelected,
  scrollToTop,
  hierarchy: parentHierarchy,
  level,
  isCleanMobileNavActive,
  isMobileNavTestActive,
  children,
}) => {
  const {
    envVars: { monetate },
    features: { monetateExperiences },
  } = useContext(EnvVarsFeaturesContext);

  const subMenuDataFiltered = {
    ...subMenuData,
    child: filterMenuData(subMenuData?.child, 'MOBILE_WEB'),
  };

  const [activeIndex, updateActiveIndex] = useState(-1);
  const hierarchy =
    level > 0 ? [...parentHierarchy, subMenuDataFiltered.name] : [];
  const styles = isCleanMobileNavActive ? stylesClean : stylesOrig;
  const isOffer = offersCategory(subMenuDataFiltered.name, 1);

  const childNavigateBack = () => {
    scrollToTop();
    setActiveLevel(level);
    updateActiveIndex(-1);
    if (isMobileNavTestActive) {
      dispatchMonetateQEvent('nav-back-button-clicked');
    }
  };

  const cn = classNames(styles.subMenu, {
    [styles.categoryLevelMenu]: level > 0,
  });

  const handleMobileMenuItemClick = (index, { id, name }) => () => {
    scrollToTop();
    setActiveLevel(level + 1);
    updateActiveIndex(index);
    if (typeof onItemSelected === 'function') {
      onItemSelected(id, index);
    }
    const monetateTags = [
      { name: 'Home & Garden', tag: 'home-and-garden-ia' },
      { name: 'Furniture & Lights', tag: 'furniture-lights-ia' },
      { name: 'Electricals', tag: 'electricals-ia' },
      { name: 'Women', tag: 'women-ia' },
      { name: 'Men', tag: 'men-ia' },
      { name: 'Beauty', tag: 'beauty-ia' },
      { name: 'Baby & Child', tag: 'baby-child-ia' },
      { name: 'Sport & Leisure', tag: 'sport-leisure-ia' },
    ];

    const { tag = '' } =
      monetateTags.find(monetateTag => monetateTag.name === name) || {};
    if (tag) {
      postMonetateImpression(monetate, tag, monetateExperiences);
    }
  };

  const isOffersRemoveViewMoreActive = isMonetateGroup(
    { monetateExperiences },
    'offers-remove-view-more'
  );

  const isStoreFinderMobileActiveB = isMonetateGroup(
    { monetateExperiences },
    'store-finder-mobile',
    'Experiment-B'
  );

  return (
    <>
      <div
        className={cn}
        role="menu"
        data-testid={formatTestId(subMenuDataFiltered.name, 'submenu-')}
      >
        {subMenuDataFiltered.backLabel && (
          <BackButton
            label={
              isMobileNavTestActive?.b
                ? subMenuDataFiltered.name
                : subMenuDataFiltered.backLabel
            }
            onClick={onNavigateBack}
            isCleanMobileNavActive={isCleanMobileNavActive}
            isMobileNavTestActive={isMobileNavTestActive}
          />
        )}
        {isStoreFinderMobileActiveB && level === 0 && <StoreFInderButton />}

        {!isMobileNavTestActive?.b ? (
          <h3
            className={classNames(styles.mobileMenu__name, {
              [styles.mobileMenu__name__offer]:
                isOffer && isCleanMobileNavActive,
            })}
          >
            {subMenuDataFiltered.url ? (
              <a
                href={subMenuDataFiltered.url}
                alt={subMenuDataFiltered.name}
                onClick={() => notifyAnalytics(subMenuDataFiltered.name)}
                data-testid={formatTestId(
                  subMenuDataFiltered.name,
                  'nav-heading-'
                )}
              >
                {subMenuDataFiltered.name}
              </a>
            ) : (
              subMenuDataFiltered.name
            )}
          </h3>
        ) : (
          !subMenuDataFiltered.backLabel && (
            <h3
              className={classNames(
                styles.mobileMenu__name,
                styles.mobileMenu__name__mobileNavTest,
                {
                  [styles.mobileMenu__name__offer]:
                    isOffer && isCleanMobileNavActive,
                }
              )}
            >
              {subMenuDataFiltered.name}
            </h3>
          )
        )}

        <ul className={styles.mobileMenuList}>
          {subMenuDataFiltered?.child?.map((menuItem, index) => {
            const { id, name, url, child, blocks } = menuItem;
            const handleMobileMenuItemClickIndex = handleMobileMenuItemClick(
              index,
              menuItem
            );
            const isCompactBlocks = blocks?.filter(
              blockItem =>
                blockItem.size === 'compact' ||
                blockItem.size === 'large-square' ||
                blockItem.size === 'content-spots' ||
                blockItem.size === 'content-spots-xl'
            );

            const isContentSpot = blocks?.filter(
              blockItem =>
                blockItem.size === 'content-spots' ||
                blockItem.size === 'content-spots-xl'
            );

            return blocks ? (
              <div
                key={id}
                className={classNames(styles.contentBlocks, {
                  [styles.contentBlocks__contentSpots]:
                    isContentSpot?.length > 0,
                })}
                data-testid="content-blocks"
              >
                <h4
                  className={styles.contentBlocks__heading}
                  data-testid={formatTestId(name, 'nav-heading-')}
                >
                  {name}
                </h4>
                <div
                  className={classNames({
                    [styles.contentBlocks__container]:
                      isCompactBlocks?.length === 0,
                  })}
                  data-testid="content-blocks-container"
                >
                  {blocks?.map(block => (
                    <ContentBlock
                      key={block.name}
                      {...block}
                      multi={blocks?.length > 1}
                      hierarchy={`${subMenuDataFiltered.name}|${name}`}
                      className={classNames(styles.contentBlocks__block, {
                        [styles.contentBlocks__contentSpot]:
                          isContentSpot?.length > 0,
                      })}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <MobileMenuItem
                key={id}
                label={name}
                url={url}
                onClick={handleMobileMenuItemClickIndex}
                child={child}
                hierarchy={hierarchy}
                isCleanMobileNavActive={isCleanMobileNavActive}
                isMobileNavTestActive={isMobileNavTestActive}
                level={level}
                isOffersRemoveViewMoreActive={isOffersRemoveViewMoreActive}
              >
                {activeIndex === index && (
                  <SubMenuChild
                    {...{
                      setActiveLevel,
                      activeIndex,
                      subMenuDataFiltered,
                      level,
                      scrollToTop,
                      hierarchy,
                      childNavigateBack,
                      isCleanMobileNavActive,
                      isMobileNavTestActive,
                    }}
                  />
                )}
              </MobileMenuItem>
            );
          })}
        </ul>
        {children}
      </div>
    </>
  );
};

function SubMenuChild({
  activeIndex,
  subMenuDataFiltered,
  level,
  scrollToTop,
  hierarchy,
  childNavigateBack,
  setActiveLevel,
  isCleanMobileNavActive,
  isMobileNavTestActive,
}) {
  const childData = {
    backLabel: subMenuDataFiltered.name,
    ...subMenuDataFiltered.child[activeIndex],
  };

  return level === 2 ? (
    <NestedMenu
      data={childData}
      onNavigateBack={childNavigateBack}
      hierarchy={hierarchy}
      isCleanMobileNavActive={isCleanMobileNavActive}
      isMobileNavTestActive={isMobileNavTestActive}
    />
  ) : (
    <SubMenu
      setActiveLevel={setActiveLevel}
      level={level + 1}
      subMenuData={childData}
      scrollToTop={scrollToTop}
      onNavigateBack={childNavigateBack}
      hierarchy={hierarchy}
      isCleanMobileNavActive={isCleanMobileNavActive}
      isMobileNavTestActive={isMobileNavTestActive}
    />
  );
}

const itemShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};
itemShape.child = PropTypes.arrayOf(PropTypes.shape(itemShape));

SubMenu.defaultProps = {
  onNavigateBack: undefined,
  onItemSelected: undefined,
  scrollToTop: undefined,
  children: undefined,
  hierarchy: [],
  isCleanMobileNavActive: false,
  isMobileNavTestActive: null,
};

const menuTestShape = {
  a: PropTypes.bool,
  b: PropTypes.bool,
};

SubMenu.propTypes = {
  subMenuData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    backLabel: PropTypes.string,
    child: PropTypes.arrayOf(PropTypes.shape(itemShape)),
  }).isRequired,
  onNavigateBack: PropTypes.func,
  onItemSelected: PropTypes.func,
  scrollToTop: PropTypes.func,
  hierarchy: PropTypes.arrayOf(PropTypes.string),
  level: PropTypes.number.isRequired,
  children: PropTypes.node,
  isCleanMobileNavActive: PropTypes.bool,
  isMobileNavTestActive: PropTypes.oneOfType([
    PropTypes.shape(menuTestShape),
    PropTypes.oneOf([null]),
  ]),
};

export default SubMenu;
