import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import BasketIcon from 'jl-design-system/elements/icons-jb/Basket24PxOutlined';
// eslint-disable-next-line import/no-named-as-default
import BasketAmount from '../basket-amount/BasketAmount';
import parseCookies from '../../../lib/cookie/parser';
import createUserProfileFrom from '../../../lib/cookie/user/profile';
import EnvVarsFeaturesContext from '../../scaffold/EnvVarsFeaturesContext';
import { UserContext } from '../../scaffold/UserContext';
import { isDesktop } from '../../utils/device/identifyDevice';
import { notifyNavAnalytics } from '../navigation/notify-analytics';
import styles from './basket.scss';

const isClient = () => typeof window !== 'undefined' && window.document;
export default function Basket({
  children: label,
  isTabbedNavActive,
  isWishlistOrStoreTestActive,
}) {
  const [componentMounted, updateComponentMounted] = useState(false);

  const {
    envVars: { headerMinibasketActive },
  } = useContext(EnvVarsFeaturesContext);

  const {
    userState: {
      user: {
        basket: { items },
      },
    },
    updateUserState,
  } = useContext(UserContext);

  const handleUserProfileUpdate = () => {
    if (isClient()) {
      const cookies = parseCookies();
      const userProfile = createUserProfileFrom(cookies);
      updateUserState(userProfile);
    }
  };

  const handleBasketButtonClick = event => {
    if (isClient() && headerMinibasketActive && window.isMinibasketLoaded) {
      event.preventDefault();
      const customEvent = new global.window.CustomEvent(
        'jl-events.browser.showMinibasket',
        {
          detail: {
            source: 'page-level',
          },
        }
      );
      window.dispatchEvent(customEvent);
    }
    if (!isDesktop()) {
      notifyNavAnalytics('mv', 'Basket');
    }
  };

  useEffect(() => {
    if (!componentMounted) {
      window.addEventListener(
        'jl-events.browser.basketQuantityUpdated',
        handleUserProfileUpdate,
        false
      );
      updateComponentMounted(true);
    }

    return () => {
      window.removeEventListener(
        'jl-events.browser.basketQuantityUpdated',
        handleUserProfileUpdate,
        false
      );
    };
  }, []);

  return (
    <a
      id="minibasket-icon-anchor"
      className={classNames(styles.link, {
        [styles.link__tn]: isTabbedNavActive,
      })}
      onClick={event => handleBasketButtonClick(event)}
      href="/basket"
      data-test="basket-anchor"
      data-testid="basket-anchor"
      aria-label="View your basket"
    >
      <BasketAmount
        basketItems={items}
        isTabbedNavActive={isTabbedNavActive}
        isWishlistOrStoreTestActive={isWishlistOrStoreTestActive}
      />
      <BasketIcon box />
      {label}
    </a>
  );
}
