const getVariantData = activeVariant => {
  switch (activeVariant) {
    case 'A-ExperimentA':
      return { exp_a: true, exp_b: false };
    case 'B-ExperimentB':
      return { exp_a: false, exp_b: true };
    case 'C-Control':
      return { exp_a: false, exp_b: false };
    default:
      return null;
  }
};
export default getVariantData;
