import React, { useContext } from 'react';
import WishlistIcon from 'jl-design-system/elements/icons-jb/Wishlist24PxOutlined';
import styles from './wishlist-icon.scss';
import { UserContext } from '../../scaffold/UserContext';
import EnvVarsFeaturesContext from '../../scaffold/EnvVarsFeaturesContext';

const WishListIcon = () => {
  const {
    userState: {
      user: { signedIn, guestWishList },
    },
  } = useContext(UserContext);

  const {
    envVars: { accountBaseUrl },
  } = useContext(EnvVarsFeaturesContext);

  const wishListUrl =
    guestWishList && !signedIn
      ? `/wish-list/guest`
      : `${accountBaseUrl}/wish-list`;

  return (
    <a
      className={styles.link}
      href={wishListUrl}
      data-test="wishlist-anchor"
      data-testid="wishlist-anchor"
      aria-label="Wish list"
    >
      <WishlistIcon box title="Wishlist" />
      <span className={styles.label} data-testid="wishlist-icon-label">
        Wish list
      </span>
    </a>
  );
};

export default WishListIcon;
