import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stylesOrig from './MobileMenu.scss';
import stylesClean from './MobileMenuClean.scss';
import MobileMenuItem from '../mobile-menu-item/MobileMenuItem';
import SubMenu from './SubMenu';
import formatTestId from '../../../utils/test-id/format-testid';

import EnvVarsFeaturesContext from '../../../scaffold/EnvVarsFeaturesContext';
import { getStaticMobileMenus } from '../../links';
import { UserContext } from '../../../scaffold/UserContext';
import FeedbackMenu from './FeedbackMenu';
import isMonetateGroup from '../../../utils/monetate/isMonetateGroup';
import filterMenuData from '../../../utils/menu/filterMenuData';
import { useAppDownloadBannerContext } from '../../../context/AppDownloadBannerContext';

// Currently ATG will not return top level categories that have isDisplayedInGlobalNav=false
// Inspiration is a category that we want to display on Mobile and not Desktop so we manually add it to the end here
// Once ATG goes away, hopefully so can this.
const InspirationLink = {
  name: 'Inspiration',
  url: '/content',
  id: '10139580012',
  includedInMenus: ['MOBILE_WEB'],
};

const MobileMenu = ({
  menus: initialMenus = [],
  fetchChildCategories,
  isCleanMobileNavActive = false,
  isMobileNavTestActive,
}) => {
  const {
    features: { monetateExperiences },
    envVars: { accountBaseUrl },
    appDownloadBanner: appDownloadBannerActive,
  } = useContext(EnvVarsFeaturesContext);
  const styles = isCleanMobileNavActive ? stylesClean : stylesOrig;
  const rootMenuName = initialMenus[0]?.name || '';

  const initialMenusFilteted = filterMenuData(
    initialMenus[0]?.child,
    'MOBILE_WEB'
  );
  const [rootMenuItems, setRootMenuItems] = useState([
    ...(initialMenusFilteted || []),
    InspirationLink,
  ]);
  const top = useRef();
  const [activeLevel, setActiveLevel] = useState(0);
  const {
    userState: {
      user: { signedIn },
    },
  } = useContext(UserContext);

  const { showAppDownloadBanner } = useAppDownloadBannerContext();

  const isPersistMobileSearch = isMonetateGroup(
    { monetateExperiences },
    'persist-mobile-search'
  );

  const isStoreFinderMobileActive = isMonetateGroup(
    { monetateExperiences },
    'store-finder-mobile'
  );

  const staticMenus = getStaticMobileMenus(
    accountBaseUrl,
    signedIn,
    isStoreFinderMobileActive
  );

  const scrollToTop = () => {
    if (top.current && top.current.scrollBy) {
      top.current.scrollBy(0, -1000);
    }
  };

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchSubMenuData = async (menuItemId, menuIndex) => {
    if (rootMenuItems[menuIndex].isCached) {
      return;
    }

    try {
      const fetchedData = await fetchChildCategories({
        id: menuItemId,
      });

      if (mounted.current === false) {
        return;
      }

      if (!fetchedData || fetchedData.error) {
        throw new Error('Fetch failed');
      }

      setRootMenuItems(existingRootMenuItems =>
        existingRootMenuItems.map((existing, i) =>
          i === menuIndex
            ? {
                ...existing,
                child: fetchedData.child || [],
                isCached: true,
              }
            : existing
        )
      );
    } catch (e) {
      console.error(`fetch failed for category id: ${menuItemId}`);
    }
  };

  const activeMenuStyles = [
    styles.activeLevelRoot,
    styles.activeLevelFirst,
    styles.activeLevelSecond,
    styles.activeLevelThird,
  ];
  const subMenuClassName = classNames([
    styles.subMenu,
    activeMenuStyles[activeLevel],
  ]);

  return (
    <>
      <nav
        className={classNames(styles.mobileMenu, {
          [styles.mobileMenu__persistSearch]: isPersistMobileSearch,
          [styles.mobileMenu__app_banner]:
            appDownloadBannerActive && showAppDownloadBanner,
          [styles.mobileMenu__mobileNavTest]:
            isMobileNavTestActive?.a || isMobileNavTestActive?.b,
          [styles.mobileMenu__defaultBreakpoint]:
            !isMobileNavTestActive?.a && !isMobileNavTestActive?.b,
        })}
        ref={top}
        data-testid="mobile-navigation-menu"
      >
        <div
          className={subMenuClassName}
          data-testid="mobile-navigation-container"
        >
          <SubMenu
            level={0}
            setActiveLevel={setActiveLevel}
            subMenuData={{
              name: rootMenuName,
              child: rootMenuItems,
              backLabel: undefined,
            }}
            onItemSelected={(menuItemId, menuIndex) => {
              fetchSubMenuData(menuItemId, menuIndex);
            }}
            scrollToTop={scrollToTop}
            isCleanMobileNavActive={isCleanMobileNavActive}
            isMobileNavTestActive={isMobileNavTestActive}
          >
            <div
              data-testid="mobile-menu-static-section"
              className={styles.staticSection}
            >
              {staticMenus.map(menu =>
                menu.feedback ? (
                  <FeedbackMenu
                    key={menu.name}
                    menu={menu}
                    isCleanMobileNavActive={isCleanMobileNavActive}
                  />
                ) : (
                  <React.Fragment key={menu.name}>
                    <h3
                      className={classNames(styles.mobileMenu__name, {
                        [styles.mobileMenu__name__static]: isCleanMobileNavActive,
                      })}
                    >
                      {menu.url ? (
                        <a
                          href={menu.url}
                          data-testid={`${formatTestId(
                            menu.name,
                            'mobile-navigation-links-'
                          )}`}
                        >
                          {menu.name}
                        </a>
                      ) : (
                        menu.name
                      )}
                    </h3>

                    {menu.child && (
                      <ul
                        className={classNames(styles.mobileMenuList, {
                          [styles.mobileMenuList__static]: isCleanMobileNavActive,
                        })}
                      >
                        {menu.child.map(menuItem => {
                          const { name, url } = menuItem;
                          return (
                            <MobileMenuItem
                              key={name}
                              label={name}
                              url={url}
                              isCleanMobileNavActive={isCleanMobileNavActive}
                              isStaticMenu
                              isMobileNavTestActive={isMobileNavTestActive}
                            />
                          );
                        })}
                      </ul>
                    )}
                  </React.Fragment>
                )
              )}
            </div>
          </SubMenu>
        </div>
      </nav>
    </>
  );
};

MobileMenu.defaultProps = {
  isCleanMobileNavActive: false,
  isMobileNavTestActive: null,
};

const itemShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};
itemShape.child = PropTypes.arrayOf(PropTypes.shape(itemShape), null);

const menuTestShape = {
  a: PropTypes.bool,
  b: PropTypes.bool,
};

MobileMenu.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
  fetchChildCategories: PropTypes.func.isRequired,
  isCleanMobileNavActive: PropTypes.bool,
  isMobileNavTestActive: PropTypes.oneOfType([
    PropTypes.shape(menuTestShape),
    PropTypes.oneOf([null]),
  ]),
};

export default MobileMenu;
